import { useState } from "react";
import styled from "styled-components";
import TableEntry from "../NewUIHelpers/TableEntry";
import { LeftOutlined, UpOutlined, CloseOutlined } from "@ant-design/icons";
import { Form } from "antd";
import { categoryNames } from "../NewUIHelpers/IconHelper";

import {
  GreyTextEntry,
  GreyDropDown,
  SplitButton,
  SplitEntry,
  CreateSplitButton,
  AntGreyTextEntry,
  AntSplitEntry,
} from "./splitComponents";

export default function ({ back, entry, form, onFinish }) {
  console.log(entry);
  const [doSplit, setDoSplit] = useState(false);
  const [category, setCategory] = useState(entry.category);
  return entry ? (
    <MainContainer>
      <TopRow>
        <BackButton>
          <LeftOutlined
            onClick={() => {
              back();
              setDoSplit(false);
            }}
          />
        </BackButton>
        Expense Details
      </TopRow>
      <TableEntry
        key={entry.key}
        date={entry.date}
        amount={entry.amount}
        description={entry.counterparty}
        category={entry.category}
        editRow={() => {}}
        id={entry.id}
        showIcon={false}
      />
      <ContentHolder>
        {doSplit && (
          <SplitExpenseHeader>
            Split Expense <CloseOutlined onClick={() => setDoSplit(false)} />
          </SplitExpenseHeader>
        )}

        <Form
          form={form}
          name="editExpense"
          onFinish={onFinish}
          onFinishFailed={() => {
            back();
          }}
        >
          {/* <GreyTextEntry label="Expense Label" starterText={entry.description}></GreyTextEntry> */}
          <AntGreyTextEntry
            label="Expense Label"
            name="label"
          ></AntGreyTextEntry>
          {/* <GreyTextEntry label="Notes" starterText={entry.note ? entry.note : ""}/> */}
          <AntGreyTextEntry label="Notes" name="note"></AntGreyTextEntry>
          {
            <AntSplitEntry
              total={entry.amount}
              name="splitAmount"
              rules={[
                {
                  required: true,
                  message: "The split amount is required.",
                },
                {
                  message:
                    "please enter a number between 0.01 - " +
                    entry.amount +
                    " dollars and cents",
                  validator: (_, value) => {
                    if (
                      parseFloat(value) >= 0.01 &&
                      parseFloat(value) <= entry.amount
                    ) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(
                        "please enter a number between 0.01 - " +
                          entry.amount +
                          " dollars and cents",
                      );
                    }
                  },
                },
              ]}
            ></AntSplitEntry>
          }
          <Form.Item name="category" initialValue={undefined}>
            <GreyDropDown
              category={category}
              setCategory={(value) => {
                setCategory(value);
                form.setFieldsValue({ category: value });
              }}
            />
          </Form.Item>

          {!doSplit && (
            <SplitButton
              onClick={() => {
                setDoSplit(true);
              }}
            >
              Split
            </SplitButton>
          )}

          <Form.Item name="submit">
            <CreateSplitButton
              form={"editExpense"}
              key="submit"
              htmlType="submit"
            >
              {!doSplit ? "Save Changes" : "Create Split"}
            </CreateSplitButton>
          </Form.Item>
        </Form>
      </ContentHolder>
    </MainContainer>
  ) : (
    <div> Error setting expense </div>
  );
}

const MainContainer = styled.div`
  background: linear-gradient(
    0deg,
    rgba(255, 222, 51, 0.8) 54.23%,
    rgba(255, 250, 138, 0.8) 81.69%,
    rgba(255, 255, 151, 0.8) 100%
  );
  width: 100%;
  height: 100%;
  // height: 100svh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: -20px;
  padding-top: 40px;
`;
const TopRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  font-size: 28px;
  fontweight: 700;
  textalign: center;
  padding-right: 40px;
`;

const BackButton = styled.button`
  border-radius: 21px;
  width: 40px;
  height: 40px;
  background: linear-gradient(180deg, #e7f5d9 0%, #adffb6 100%);
  box-shadow: 0px 2px 8px 0px #0b0d1626;
  padding: 6px;
  border: none;
`;

const ContentHolder = styled.div`
  background: #ffffff;
  width: 100%;
  border-radius: 36px 36px 0 0;
  box-shadow: 0px -4px 12px 0px #00000014;
  display: flex;
  flex-direction: column;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 30px;
  flex: 1;
  margin-top: 20px;
`;

const SplitExpenseHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 20px;
  line-height: 27px;
`;
