import { useState, useRef, useEffect } from "react";
// import categoryData from "../../CFTSetup/Forms/Data";
import getIcontSVG from "./IconHelper";

import styled from "styled-components";

const iconNameList = [
  "Family",
  "Food",
  "Home & Garden",
  "Entertainment",
  "Health",
  "Transportation",
  "Utility",
  "Other",
];

function TableEntry(props) {
  /*
  props should be:
  date: date
  amount: number or string?
  description: string
  category: string
  onclicked: () => void
  */

  let SVG = null;
  if (props.category) {
    SVG = getIcontSVG(props.category);
  }
  if (props.showIcon !== null && props.showIcon === false) {
    SVG = null;
  }

  return (
    <MainContainer
      onClick={() => {
        props.editRow();
      }}
    >
      <LeftSide>
        {SVG && (
          <Icon>
            <SVG />
          </Icon>
        )}
        <LeftColumn>
          <p className="top-bottom">{props.date}</p>
          <p>{props.description}</p>
          <p className="top-bottom">Visa 0567</p>
        </LeftColumn>
      </LeftSide>

      <div>
        <p>${props.amount}</p>
      </div>
    </MainContainer>
  );
}

export default TableEntry;

export const TABLE_ENTRY_HEIGHT = 85;

const MainContainer = styled.div`
  width: 363px;
  height: ${TABLE_ENTRY_HEIGHT}px;
  min-height: 80px;
  border-radius: 18px;
  background-color: #ffffff;
  box-shadow: 0px 2px 8px 0px #d4d4d4cc;
  margin-top: 10px;
  padding-right: 15px;
  padding-left: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  color: black;
  font-size: 18px;
  p {
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;

const LeftSide = styled.div`
  display: flex;
  align-items: center;
`;
const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 10px;
  .top-bottom {
    font-size: 12px;
    color: #b7b7b7;
  }
`;

const Icon = styled.svg`
  height: 30px;
  width: 30px;
  margin-right: 10px;
  margin-left: 10px;
`;
