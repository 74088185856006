import styled from "styled-components";
import { ReactComponent as PlusCircle } from "../../../assets/img/Other/PlusCircle.svg";
import { ReactComponent as MinusCircle } from "../../../assets/img/Other/MinusCircle.svg";
import SVGHeader from "./SVGHeader";

export default function ShortContent({ dateString, category, budgetInfo }) {
  const headerLabel = category;
  const remaining = budgetInfo ? budgetInfo.remaining : "Loading";
  const planned = budgetInfo ? budgetInfo.budgetAmount : "Loading";
  const spent = budgetInfo ? budgetInfo.actualSpend : "Loading";
  return (
    <MainContainer>
      <HeaderInfo>
        <SVGHeader headerLabel={headerLabel} />
        <DateText>{dateString}</DateText>
      </HeaderInfo>

      <InfoMain>
        <InfoLeft>
          <InfoLeftRow>
            <PlusCircle />
            <div>
              <p className="label">Total Planned</p>
              <p className="amount">{"$" + planned}</p>
            </div>
          </InfoLeftRow>
          <InfoLeftRow>
            <MinusCircle />
            <div>
              <p className="label">Total Spent</p>
              <p className="amount">
                {(remaining > 0 ? "-" : "") + "$" + spent}
              </p>
            </div>
          </InfoLeftRow>
        </InfoLeft>
        <InfoRight>
          <p className="label">Total Remaining</p>
          <p className="amount">{"$" + remaining}</p>
        </InfoRight>
      </InfoMain>
      <BottomMessage>
        <p className="green">Keep it up, Powell!</p>
        <p className="bottom">
          You're on track to meet your MoneyFlow goals this month.
        </p>
      </BottomMessage>
    </MainContainer>
  );
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 94%;
  margin-left: 3%;
`;

const HeaderInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 85%;
`;

const DateText = styled.p`
  font-size: 18px;
  line-height: 25px;
  text-align: right;
  color: #9d9d9d;
`;

const InfoMain = styled.div`
  background: linear-gradient(180deg, #e7f5d9 1.7%, #adffb6 160.55%);
  box-shadow: 0px 2px 5px 0px #d4d4d499;
  min-height: 128px;
  min-width: 360px;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 0 20px;
`;

const InfoLeft = styled.div`
  width: 140px;
  height: 104px;
  padding: 5px 10px 5px 10px;
  border-radius: 8px;
  background-color: #f3fff4cc;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

const InfoLeftRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  p {
    margin: 0;
  }
  .label {
    font-size: 10px;
    font-weight: 300;
    line-height: 14px;
    color: #0b0d16;
  }
  .amount {
    font-size: 16px;
    font-weight: 400;
  }
`;

const InfoRight = styled.div`
  text-align: right;
  .label {
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    margin: 0;
  }
  .amount {
    color: #25b764;
    font-weight: 500;
    font-size: 40px;
    margin: 0;
  }
`;

const BottomMessage = styled.div`
  width: 360px;
  height: 64px;
  box-shadow: 0px 2px 5px 0px #d4d4d499;
  border-radius: 12px;
  padding: 10px 20px 10px 20px;
  margin-top: 18px;

  p {
    margin: 0;
  }
  .green {
    font-size: 18px;
    line-height: 25px;
    color: #00af15;
  }
  .bottom {
    font-size: 12px;
    line-height: 17px;
    color: #919191;
  }
`;
