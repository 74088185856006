import ShortContent from "./ShortContent";
import TallContent from "./TallContent";

export default function DrawerContent({
  isDrawerShort,
  category,
  dateString,
  budgetInfo,
  editPlanned,
  editDate,
  tableData,
  editRow,
  onFinish,
  setEditID,
}) {
  const isTall = !isDrawerShort;
  console.log(tableData);
  return (
    <>
      {!isTall && (
        <ShortContent
          dateString={dateString}
          category={category}
          budgetInfo={budgetInfo}
        />
      )}
      {isTall && (
        <TallContent
          editDate={editDate}
          dateString={dateString}
          category={category}
          tableData={tableData}
          onFinish={onFinish}
          setEditID={setEditID}
        />
      )}
    </>
  );
}
