import getIcontSVG from "../NewUIHelpers/IconHelper";
import styled from "styled-components";

export default function SVGHeader(props) {
  const headerLabel =
    props.headerLabel == "All" ? "All Categories" : props.headerLabel;
  const SVG = getIcontSVG(props.headerLabel);
  const fontSize = props.fontSize ? props.fontSize : "24px";

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <SVG height={fontSize} />
      <HeaderText fontSize={fontSize}>{headerLabel}</HeaderText>
    </div>
  );
}

const HeaderText = styled.p`
  font-size: ${(props) => (props.fontSize ? props.fontSize : "24px")};
  line-height: 33px;
  margin-right: auto;
  margin-left: 10px;
`;
