import styled from "styled-components";
import getIcontSVG, { categoryNames } from "../NewUIHelpers/IconHelper";
import { UpOutlined } from "@ant-design/icons";
import { Form, InputNumber, Input } from "antd";
import { useState, useRef, useEffect } from "react";

export const SmallText = styled.div`
  position: absolute;
  top: -10px;
  left: 10px;
  z-index: 10;
  background-color: white;
  flex-grow: 0;
  display: inline-block;
  padding: 0 4px;
  color: #a0a0a0;
  font-size: 12px;
  max-height: 21px;
`;
export const StyleInput = styled.input`
  height: 49px;
  width: 322px;
  border-radius: 15px;
  background: #f6f6f6;
  border: 1.5px solid #d9d9d9;
  padding-left: 15px;
`;

const AntStyleInput = styled(Input)`
  height: 49px;
  width: 322px;
  border-radius: 15px;
  background: #f6f6f6;
  border: 1.5px solid #d9d9d9;
  padding-left: 15px;
`;

export const AntGreyTextEntry = ({ label, starterText, name }) => {
  return (
    <div style={{ position: "relative", marginTop: "20px" }}>
      <SmallText>{label}</SmallText>
      <Form.Item name={name}>
        <AntStyleInput defaultValue={starterText ? starterText : ""} />
      </Form.Item>
    </div>
  );
};

export const GreyTextEntry = ({ label, starterText }) => {
  // const label = "Expense Label";

  return (
    <div style={{ position: "relative", marginTop: "20px" }}>
      <SmallText>{label}</SmallText>

      <StyleInput defaultValue={starterText ? starterText : ""} />
    </div>
  );
};

export const GreyDropDown = ({ category, setCategory }) => {
  const SVG = getIcontSVG(category ? category : "other");
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  const handleDocumentClick = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      console.log("clicked outside");
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const categoryButtons = [];

  for (let i = 1; i < categoryNames.length; i++) {
    const tempSVG = getIcontSVG(i);
    categoryButtons.push(
      <CategoryButton
        className="inList"
        type="button"
        key={i}
        onClick={() => {
          setCategory(i);
          setIsOpen(false);
        }}
      >
        <Icon as={tempSVG} />
        <p>{categoryNames[i]}</p>
      </CategoryButton>,
    );
  }
  return (
    // <div style={{ position: "relative", marginTop: "20px" }}>
    // <SmallText>{"asdf"}</SmallText>

    <CategorySelectContainer>
      <SmallText>{"Category"}</SmallText>
      <CategoryButton
        type="button"
        ref={buttonRef}
        onClick={() => {
          setIsOpen(!isOpen);
          console.log("dropdown clicked");
        }}
      >
        <Icon as={SVG} />
        <p>{categoryNames[category] ? categoryNames[category] : category}</p>
        <DownArrowIcon />
      </CategoryButton>
      {isOpen && (
        <CategoryDropdown ref={dropdownRef}>{categoryButtons}</CategoryDropdown>
      )}
    </CategorySelectContainer>
    // </div>
  );
};

export const CategorySelectContainer = styled.div`
  position: relative;
  margin-top: 20px;
  height: 49px;
  width: 230px;
  border-radius: 15px;
  background: #f6f6f6;
  border: 1.5px solid #d9d9d9;
  padding-left: 15px;
  padding: 5px;
`;
export const CategoryButton = styled.button`
  display: flex;
  background: white;
  border: none;
  border-radius: 15px;
  box-shadow: 0px 0px 6px 0px #0b0d1640;
  align-items: center;
  width: 100%;
  max-height: 100%;
  &.inList {
    box-shadow: none;
    border-radius: 0;
  }
`;

const CategoryDropdown = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: white;
  border: none;
  border-radius: 15px;
  box-shadow: 0px 0px 6px 0px #0b0d1640;
  align-items: center;
  width: 100%;
  height: 200px;
  z-index: 10;
  overflow: auto;
`;

export const DownArrowIcon = styled(UpOutlined)`
  transform: rotate(180deg);
  flex: 1;
`;
export const Icon = styled.svg`
  height: 30px;
  width: 30px;
  margin-right: 10px;
  margin-left: 10px;
`;

export const SplitButton = styled.button`
  //this position relative doesn't seem to do anything so I commented it out -Aidan 11/8/24
  // position: relative;
  margin-top: 20px;
  background-color: #ffffff;

  border-radius: 15px;
  border: 2.37px solid #fffa8a;
  // border: 2.37px solid #ffde33;

  box-shadow: 0px 0px 6px 0px #d4d4d4cc;
  width: 100px;
  padding-top: 8px;
  padding-bottom: 8px;

  // border-image-source: linear-gradient(180deg, #fffa8a 0%, #ffde33 100%);
  // border-image-slice: 1;
`;

export const AntSplitEntry = ({ total, name, rules }) => {
  const maxSplit = total ? total : -1;
  return (
    <SplitEntryContainer>
      <SmallText>{"Split Amount"}</SmallText>
      <Form.Item name={name} rules={rules} hasFeedback>
        <AntStyleInput type="number" step={0.01}></AntStyleInput>
      </Form.Item>
      <TotalReminderText>Of {total ? total : -1}</TotalReminderText>
    </SplitEntryContainer>
  );
};

export const SplitEntry = (props) => {
  const total = props.total ? props.total : 35;
  return (
    <SplitEntryContainer>
      <SmallText>{"Split Amount"}</SmallText>
      <StyleInput type="number" step={0.01}></StyleInput>
      <TotalReminderText>Of {total}</TotalReminderText>
    </SplitEntryContainer>
  );
};

export const SplitEntryContainer = styled.div`
  position: relative;
  margin-top: 20px;
`;

export const TotalReminderText = styled.p`
  position: absolute;
  top: 2px;
  right: 40px;
  color: #b6b6b6;
`;

export const CreateSplitButton = styled.button`
  background: linear-gradient(180deg, #fffa8a -25.51%, #ffde33 258.16%);
  box-shadow: 0px 2px 8px 0px #0b0d1626;
  margin-top: 20px;
  border-radius: 40px;
  border: none;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 16px;
  width: 100%;
`;
