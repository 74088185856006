import { useState } from "react";
import { Link } from "react-router-dom";
import { Drawer } from "antd";

import { useUser } from "../../state/UserProvider";
import { useCheckResponseFail } from "../../hooks/useCheckResponseFail";
import backend from "../../functions/backend";
import { getPreviousMonth } from "../../functions/timeDate";
import {
  useSettings,
  serverSetPage,
  updateSetupStep1,
} from "../../state/SettingsProvider";

import { setMoneyFlowToken } from "../../state/stateApplication";
import Hamburger_icon from "../../assets/img/Header/Hamburger_icon.svg";
import styled from "styled-components";

function MoneyFlowMenu() {
  const [open, setOpen] = useState(false);
  const { currentUser } = useUser();
  const { wheelSettings, dispatchSettings } = useSettings();
  const checkResponseFail = useCheckResponseFail();

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  function clickLogout(event) {
    console.log("click logout");
    setMoneyFlowToken("");
    // event.preventDefault();
  }

  function resetcount() {
    dispatchSettings({ type: "DEBUG_RESET" });
    serverSetPage(currentUser.money_wheel_settings_id, 0);
  }

  // FYI: while it's 6pm July 31 in PST,  it's already Aug 1 in UTC.
  // The server thinks it's already Aug 1, because the server is always using UTC,
  // so it generates new fake tx for the current date, Aug 1.
  // Meanwhile, the drawer and expense review will only show July tx,
  // because it's still July locally.  This is the correct functionality.

  // Creates a randomly categorized or uncategorized transaction.
  // If no argument passed, then the tx has today's date (UTC).
  // If month is "lastmonth", then use a date from the previous month.
  // If month and year is provided, then use random date within that month, year.
  //
  // IN: month is 1..12
  async function createFakeTx(month, year) {
    console.log("createFakeTx() for user_id = ", currentUser.id);
    const data = {
      user_id: currentUser.id,
      /*
      // causes 500 (INTERNAL SERVER ERROR)
      categorization_type: "uncategorized",
      tx_category_id: 0,
      tx_subcategory_id: 0,
      tx_user_subcategory_id: 0,
      */
    };
    if (month === "lastmonth") {
      const prevMonth = getPreviousMonth();
      data.month = prevMonth.lastMonth; // 1..12
      data.year = prevMonth.lastYear;
    } else if (month && year) {
      data.month = month; // 1..12
      data.year = year;
    }
    const response = await backend.post("/v1/tx/fake", data);
    checkResponseFail(response, "Failed to create fake transaction:");
    if (response.success) {
      console.log("Created new fake transaction");
      console.log(response);
    }
  }

  const currentPage = window.location.pathname;

  return (
    <>
      <img
        src={Hamburger_icon}
        alt="Menu"
        onClick={showDrawer}
        className="debug-menu"
      />
      <Drawer title="Debug Menu" placement="left" onClose={onClose} open={open}>
        <MenuList>
          <MenuItem>
            <MenuItemLink to="/profile-page" state={currentPage}>
              Profile
            </MenuItemLink>
          </MenuItem>
          <MenuItem>
            <MenuItemLink to="/accounts-page" state={currentPage}>
              Accounts
            </MenuItemLink>
          </MenuItem>
          <MenuItem>
            <MenuItemLink to="/help">Help</MenuItemLink>
          </MenuItem>
          <MenuItem>
            <MenuItemLink to="/login" onClick={clickLogout}>
              Logout
            </MenuItemLink>
          </MenuItem>
          <MenuItem>
            <MenuItemLink
              to="/money-wheel-setup"
              onClick={() => {
                resetcount();
                onClose();
              }}
            >
              Debug: Go to CFTSetup Start
            </MenuItemLink>
          </MenuItem>
          <MenuItem>
            <MenuItemLink
              to="/"
              onClick={() => {
                resetcount();
                onClose();
              }}
            >
              Debug: Go to Login
            </MenuItemLink>
          </MenuItem>
          <MenuItem>
            <MenuItemLink
              to="/expense-review"
              onClick={() => {
                updateSetupStep1(
                  wheelSettings.SETUP_STEP_EXPENSE_REVIEW_TOUR,
                  currentUser.money_wheel_settings_id,
                  dispatchSettings,
                );
                onClose();
              }}
            >
              Debug: If in Expense Review, Return to Expense Review Tour
            </MenuItemLink>
          </MenuItem>
          <MenuItem>
            <MenuItemLink
              to="/money-wheel"
              onClick={() => {
                updateSetupStep1(
                  wheelSettings.SETUP_STEP_ACTIVE_WHEEL_TOUR,
                  currentUser.money_wheel_settings_id,
                  dispatchSettings,
                );
                onClose();
              }}
            >
              Debug: If in Active Wheel, Return to Active Wheel Tour
            </MenuItemLink>
          </MenuItem>
          <MenuItem>
            <MenuItemLink to="#" onClick={createFakeTx}>
              Debug: Add Fake Transaction with Today's Date
            </MenuItemLink>
          </MenuItem>
          <MenuItem>
            <MenuItemLink to="#" onClick={() => createFakeTx("lastmonth")}>
              Debug: Add Fake Transaction with Last Month's Date
            </MenuItemLink>
          </MenuItem>
          <MenuItem>
            <MenuItemLink to="#" onClick={() => createFakeTx(8, 2024)}>
              Debug: Add Fake Transaction in 8/2024
            </MenuItemLink>
          </MenuItem>
          <MenuItem>
            <MenuItemLink
              to="/credit-wheel"
              onClick={() => {
                updateSetupStep1(
                  wheelSettings.SETUP_STEP_CREDIT_WHEEL_TOUR,
                  currentUser.debt_settings_id,
                  dispatchSettings,
                  "debt",
                );
                onClose();
              }}
            >
              Debug: If in Credit Wheel, Return to Credit Wheel Tour
            </MenuItemLink>
          </MenuItem>
          <MenuItem>
            <MenuItemLink
              to="/goals-wheel"
              onClick={() => {
                updateSetupStep1(
                  wheelSettings.SETUP_STEP_GOALS_WHEEL_TOUR,
                  currentUser.goal_settings_id,
                  dispatchSettings,
                  "goal",
                );
                onClose();
              }}
            >
              Debug: If in Goals Wheel, Return to Goals Wheel Tour
            </MenuItemLink>
          </MenuItem>
        </MenuList>
      </Drawer>
    </>
  );
}

export default MoneyFlowMenu;

const MenuList = styled.ul`
  list-style-type: none;
  padding-left: 0;
`;

const MenuItem = styled.li`
  /* Minimum height for pressable buttons is 48px.
   * MenuItem height is 1 em padding + 1 em text + 1 em padding.
   * So MenuItem height is 3em = 48px, on mobile.
   */
  padding: 1em;
  &:hover {
    background-color: #eeeeee;
    cursor: pointer;
  }
`;

const MenuItemLink = styled(Link)`
  color: #333333;
  &:hover {
    color: #333333;
    cursor: pointer;
  }
`;

export function DebugMenu() {
  return (
    <DebugMenuStyle>
      <MoneyFlowMenu />
    </DebugMenuStyle>
  );
}

const DebugMenuStyle = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: transparent;
  padding: 10px;
  z-index: 1000;

  img.debug-menu {
    filter: grayscale(100%);
    opacity: 0.1;
  }
`;
