import styled from "styled-components";
import { useState, useEffect } from "react";
import { DownOutlined } from "@ant-design/icons";
import { Form } from "antd";

import SVGHeader from "./SVGHeader";
import PageSelector from "./PageSelector";
import TableHeader from "./TableHeader";
import TableEntry, { TABLE_ENTRY_HEIGHT } from "../NewUIHelpers/TableEntry";
import ExpenseDetails from "./ExpenseDetails";

const MARGIN_LEFT = "35px";

export default function TallContent({
  editDate,
  dateString,
  category,
  tableData,
  onFinish,
  setEditID,
}) {
  const headerLabel = category;

  const [showExpenseDetails, setShowExpenseDetails] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [filteredData, setFilteredData] = useState(tableData);
  const [form] = Form.useForm();
  useEffect(() => {
    // update filteredData, when tableData changes due to date changer
    setFilteredData(tableData);
  }, [tableData]);
  const searchFunction = (searchTerm) => {
    const filtered = tableData.filter((entry) => {
      return entry.description.toLowerCase().includes(searchTerm.toLowerCase());
    });
    setFilteredData(filtered);
  };
  const tableEntries = filteredData.map((entry) => (
    <TableEntry
      key={entry.id}
      date={entry.date}
      amount={entry.amount}
      description={entry.description}
      // category={category === "All" ? entry.category : null}
      category={entry.category}
      editRow={() => {
        setShowExpenseDetails(true);
        setSelectedEntry(entry);
        console.log("1123");
        console.log(entry.id);
        setEditID(entry.id);

        const values = {
          splitAmount: entry.amount,
          label: entry.description,
          note: entry.note !== null ? entry.note : "",
        };
        form.setFieldsValue(values);
      }}
      id={entry.id}
    />
  ));

  return showExpenseDetails ? (
    <ExpenseDetails
      back={() => {
        setShowExpenseDetails(false);
      }}
      entry={selectedEntry}
      form={form}
      onFinish={onFinish}
    />
  ) : (
    <MainContainer>
      {/* <h1> Tall Content </h1> */}
      {/* header and date section */}

      <div style={{ marginLeft: MARGIN_LEFT }}>
        <DateSelector
          onClick={() => {
            editDate();
          }}
        >
          {dateString}
          <DownOutlined style={{ marginLeft: "8px" }} />
        </DateSelector>
        <SVGHeader headerLabel={headerLabel} fontSize="28px" />
      </div>
      <TableHolder>
        <PageSelector />
        <TitleText>All Expenses</TitleText>
        <Table>
          <TableHeader searchFunction={searchFunction} />
        </Table>
      </TableHolder>

      <TableElementsContainer>{tableEntries}</TableElementsContainer>
    </MainContainer>
  );
}

const MainContainer = styled.div`
  background: linear-gradient(180deg, #d9f7bd 0%, #adffb6 39.91%);
  width: 100%;
  height: 100%;
  // height: 100svh;
  display: flex;
  flex-direction: column;
`;

//might need to change this to its own component when we add date selecting fucntionality
const DateSelector = styled.button`
  box-shadow: 0px 4px 4px 0px #d4d4d466;
  background: #f3fff4cc;
  border-radius: 20px;
  border: none;
  height: 40px;
  padding: 10px 20px 10px 20px;

  align-self: flex-start;
`;

const TableHolder = styled.div`
  background: #ffffff;
  width: 100%;
  border-radius: 36px 36px 0 0;
  display: flex;
  flex-direction: column;
`;

const TitleText = styled.p`
  font-size: ${(props) => (props.fontSize ? props.fontSize : "20px")};
  line-height: 33px;
  margin-left: ${MARGIN_LEFT};
  margin-top: 40px;
`;

const Table = styled.div`
  background: #f0f0f0;
  width: 100%;
  border-radius: 36px 36px 0 0;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
`;

const TableElementsContainer = styled.div`
  background: #f0f0f0;
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  margin-bottom: 10px;
  padding-bottom: ${TABLE_ENTRY_HEIGHT}px;
`;
