import { useMemo } from "react";
import { useUser } from "../../../state/UserProvider";
import { Button, Modal, Form, Select, InputNumber, Input } from "antd";
import styled, { createGlobalStyle } from "styled-components";
import {
  SplitEntry,
  GreyTextEntry,
  SplitButton,
  CreateSplitButton,
  AntGreyTextEntry,
  AntSplitEntry,
} from "../DrawerComponents/splitComponents";
import TableEntry from "../NewUIHelpers/TableEntry";
import { useState, useRef } from "react";

function DescriptionFormItem() {
  return (
    <>
      {/* <label htmlFor="label">Description</label> */}
      {/* <Form.Item name="label">
        <Input placeholder="Description" />
      </Form.Item> */}

      <AntGreyTextEntry label="Expense Label" name="label"></AntGreyTextEntry>
    </>
  );
}
function NotesFormItem() {
  return (
    <>
      <AntGreyTextEntry label="Notes" name="note"></AntGreyTextEntry>
    </>
  );
}

function SplitAmountFormItem({ maxSplit }) {
  return (
    <>
      {/* <label htmlFor="splitAmount">Split Amount</label> */}
      <AntSplitEntry
        total={maxSplit}
        name="splitAmount"
        rules={[
          {
            required: true,
            message: "The split amount is required.",
          },
          {
            message:
              "please enter a number between 0.01 - " +
              maxSplit +
              " dollars and cents",
            validator: (_, value) => {
              if (parseFloat(value) >= 0.01 && parseFloat(value) <= maxSplit) {
                return Promise.resolve();
              } else {
                return Promise.reject(
                  "please enter a number between 0.01 - " +
                    maxSplit +
                    " dollars and cents",
                );
              }
            },
          },
        ]}
      ></AntSplitEntry>
    </>
  );
}

function CategoryFormItem({ isCategorized }) {
  const { currentUser } = useUser();

  const isFamily =
    currentUser.marital_status !== "single" ||
    currentUser.dependents.length > 0;

  const optionNotCategorized = useMemo(() => {
    // if already categorized, do not allow recategorizing to "Not Categorized"
    return isCategorized ? [] : [{ value: 0, label: "Not Categorized" }];
  }, [isCategorized]);

  const categoryOptions = useMemo(
    () => [
      ...optionNotCategorized,
      { value: 1, label: isFamily ? "Family" : "Personal" },
      { value: 2, label: "Food" },
      { value: 3, label: "Home" },
      { value: 4, label: "Entertainment" },
      { value: 5, label: "Health" },
      { value: 6, label: "Transportation" },
      { value: 7, label: "Utility" },
      { value: 8, label: "Other" },
      { value: 101, label: "Income" },
      { value: 201, label: "Transfer" },
    ],
    [isFamily, optionNotCategorized],
  );

  return (
    <>
      <span id="category_select">
        <label htmlFor="category">Change Category</label>
        <Form.Item name="category">
          <Select options={categoryOptions}></Select>
        </Form.Item>
      </span>
    </>
  );
}

function FormFooterButtons({ formName, handleCancel }) {
  return (
    <>
      <span style={{ display: "inline-block" }}>
        <Button onClick={handleCancel}>Cancel</Button>
      </span>
      <span style={{ display: "inline-block" }}>
        <Form.Item name="submit">
          {/* <Button form={formName} key="submit" htmlType="submit">
            Submit
          </Button> */}
          <CreateSplitButton form={formName} key="submit" htmlType="submit">
            Done
          </CreateSplitButton>
        </Form.Item>
      </span>
    </>
  );
}

export default function ExpenseDetailsModal({
  form2,
  isOpen,
  setIsOpen,
  onFinish,
  maxSplit,
}) {
  function closeModal() {
    setIsOpen(false);
  }

  return (
    <Modal
      title="Expense Details"
      open={isOpen}
      onOk={closeModal}
      footer={null}
      onCancel={closeModal}
    >
      <Form
        form={form2}
        name="editDetails"
        onFinish={onFinish}
        onFinishFailed={closeModal}
      >
        <DescriptionFormItem />
        <SplitAmountFormItem maxSplit={maxSplit} />
        <CategoryFormItem
          isCategorized={form2.getFieldValue("category") !== 0}
        />
        <FormFooterButtons formName="editDetails" handleCancel={closeModal} />
      </Form>
    </Modal>
  );
}

export function ExpenseDetailsCircleModal({
  form4,
  isOpen,
  setIsOpen,
  onFinish,
  maxSplit,
  expenseInfo,
}) {
  console.log("expenseInfo");
  console.log(expenseInfo);
  let counterparty = "";
  let amount = "";
  let account_type = "";
  let posted_date = "";
  let originalAmount = "";
  if (expenseInfo) {
    counterparty = expenseInfo.counterparty;
    amount = expenseInfo.amount;
    account_type = expenseInfo.account_type;
    posted_date = expenseInfo.posted_date;
    originalAmount = expenseInfo.original_amount;
  }

  function closeModal() {
    setIsOpen(false);
    setDoSplit(false);
  }
  const [doSplit, setDoSplit] = useState(false);

  return (
    <>
      <GlobalStyle />
      <Modal
        title={doSplit ? "Split Expense" : "Expense Details"}
        open={isOpen}
        onOk={closeModal}
        footer={null}
        onCancel={closeModal}
        className="test-test"
      >
        {/* <ModalBackground /> */}
        <Form
          form={form4}
          name="editDetailsCircle"
          onFinish={onFinish}
          onFinishFailed={closeModal}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <ExpenseDisplay bold={true}>
              <p>{counterparty}</p>
              <p>
                ${amount}
                {amount !== originalAmount ? " of $" + originalAmount : ""}
              </p>
            </ExpenseDisplay>
            <ExpenseDisplay bold={false}>
              <p>{account_type}</p>
              <p>{posted_date}</p>
            </ExpenseDisplay>
            <DescriptionFormItem />
            <NotesFormItem />

            {doSplit ? (
              <SplitAmountFormItem maxSplit={maxSplit} />
            ) : (
              <SplitButton
                onClick={() => {
                  setDoSplit(true);
                }}
              >
                Split
              </SplitButton>
            )}
            <FormFooterButtons
              formName="editDetailsCircle"
              handleCancel={closeModal}
            />
          </div>
        </Form>
      </Modal>
    </>
  );
}

const GlobalStyle = createGlobalStyle`
  .ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.2) !important;
    backdrop-filter: blur(5px); /* Adjust the blur radius as needed */
  }
`;

const ExpenseDisplay = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 10px;
  width: 100%;
  p {
    ${(props) =>
      props.bold
        ? `color: #0B0D16;
        font-size: 18px;
        font-weight: 700;
        line-height: 25px;
        margin-bottom: 0;`
        : `color: #666666;
        font-size: 12px;
        line-height: 17px;`}
  }
`;
